import React, { useState, useEffect, useContext } from 'react';
import ReactHTMLParser from 'react-html-parser';
import { marked } from 'marked';
import { AccordionSidebar } from '@latitude/accordion';
import { Box } from '@latitude/box';
import { Heading4, Heading5 } from '@latitude/heading';
import { ImportantInformation } from '@latitude/important-information';
import { List, ListItem } from '@latitude/list';
import { Metadata } from '@latitude/metadata';
import { FeaturesSlider } from '@latitude/features-slider';
import {
  ALIGN,
  BREAKPOINT,
  COLOR,
  HEADING,
  MARGIN
} from '@latitude/core/utils/constants';
import Layout from '@/components/layout';
import Lframe from '@/components/Lframe/Lframe';
import { SITE_URL, TITLE_SUFFIX } from '@/utils/constants';
import HorizontalRule from '../../components/HorizontalRule/HorizontalRule';
import Hero from './_hero';
import DigitalWalletsLinks from './_links';
import faqs from './_faqs';
import imgHowToUse from './images/garmin-pay-watch-all-cards.webp';
import { PageContext } from '@/context/PageContext';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import { useSetState } from '@/utils/hooks';

const featureSliderItems = [
  {
    icon: 'icon-easy',
    title: 'Easy',
    text:
      'Garmin Pay lets you make purchases quickly and almost effortlessly with nothing needed but your watch. No wallet? No phone? No problem.'
  },
  {
    icon: 'icon-padlock-confetti',
    title: 'Secure',
    text:
      'Garmin Pay protects you by using watch-specific card numbers and transaction codes every time you make a purchase.'
  },
  {
    icon: 'icon-tick-circle-confetti',
    title: 'Convenient',
    text:
      'Where can you use Garmin Pay? Almost anywhere you can make contactless payments.'
  }
]

const GarminPay = ({ location }) => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};

  const featureSliderData = [
    {
      heading: 'Why Garmin Pay?',
      featureCards: featureSliderItems
    }
  ];
  /** - END - */

  return (
    <Layout location={location}>
      <main className="navigation-spacer">
        <Metadata
          title={`Garmin Pay & Wallet Setup | Mobile Tap & Pay |  ${TITLE_SUFFIX}`}
          description="Garmin Pay is now available to Latitude customers. See how to setup Garmin Pay on your Android devices and make your payments more simple and secure."
          canonical={`${SITE_URL}${location.pathname}`}
        />
        <Lframe payAnyWay />
        <Hero />
        <DigitalWalletsLinks location={location} />
        <Box backgroundColor={COLOR.WHITE}>
          <FeaturesSlider
            key={
              (state?.featureSliderData?.[0] || featureSliderData[0]).heading
            }
            heading={
              (state?.featureSliderData?.[0] || featureSliderData[0]).heading
            }
            subheading={
              (state?.featureSliderData?.[0] || featureSliderData[0])
                .description
            }
            data={
              (state?.featureSliderData?.[0] || featureSliderData[0])
                .featureCards
            }
          />
        </Box>
        <Box.Section
          backgroundColor={COLOR.GREY6}
          css={`
            padding: 8px 0;
            @media (min-width: ${BREAKPOINT.LG}) {
              padding: 40px 0;
            }
          `}
        >
          <Box padding="0 15px">
            <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
              Set up and use Garmin Pay
            </Heading4>
            <Box
              css={`
                display: grid;
                grid-gap: ${MARGIN.M24} ${MARGIN.M40};
                h5 {
                  margin-bottom: ${MARGIN.M16};
                  color: ${COLOR.BLACK};
                }
                @media (max-width: ${BREAKPOINT.SM}) {
                  h5 {
                    font-size: ${HEADING['h6'].FONT_SIZE.NORMAL};
                    line-height: ${HEADING['h6'].LINE_HEIGHT.NORMAL};
                  }
                }
                @media (min-width: ${BREAKPOINT.LG}) {
                  grid-template-columns: repeat(3, 1fr);
                  grid-template-rows: 1;
                }
              `}
            >
              <Box>
                <Heading5>Set up on device</Heading5>
                <List>
                  <ListItem>
                    Once you&apos;ve installed the Garmin app onto your iOS or
                    Android smartphone, you can add your Latitude card to Garmin
                    Pay.
                  </ListItem>
                  <ListItem>
                    Tap &apos;Create your wallet&apos; in the Garmin app and
                    enter a 4-digit passcode.
                  </ListItem>
                  <ListItem>
                    Tap Visa to either fill in your card details or scan them
                    using your smartphone&apos;s camera.
                  </ListItem>
                  <ListItem>
                    We&apos;ll send you a one-time NetCode SMS. Use it to
                    confirm you want to link your Latitude to Garmin Pay. Your
                    card will then be added to the virtual wallet on your Garmin
                    Vivoactive 3 device.
                  </ListItem>
                </List>
              </Box>
              <Box>
                <Heading5>How to use</Heading5>
                <List>
                  <ListItem>
                    To pay using your Garmin Vivoactive 3 device, press and hold
                    the action button then tap the wallet icon, enter your
                    4-digit passcode and hold your smartwatch to the card reader
                    to pay. A green tick confirms it’s been successful.
                  </ListItem>
                  <ListItem>
                    To remove your card, simply tap the Vivoactive 3 device page
                    in the Garmin Connect app &gt; Manage Wallet &gt; Swipe to
                    find the card to remove &gt; Tap Delete Card.
                  </ListItem>
                </List>
              </Box>
              <Box
                css={`
                  text-align: center;
                  @media (min-width: ${BREAKPOINT.LG}) {
                    grid-area: 1/1;
                  }
                `}
              >
                <img
                  css={`
                    max-width: 280px;
                  `}
                  src={imgHowToUse}
                  alt="Watch - Garmin Pay"
                />
              </Box>
            </Box>
          </Box>
        </Box.Section>
        <HorizontalRule />
        <Box.Section
          backgroundColor={COLOR.GREY6}
          css={`
            padding: 8px 0;
            @media (min-width: ${BREAKPOINT.LG}) {
              padding: 40px 0;
            }
            .accordion__item-body .accordion__item-body {
              padding-top: 16px !important;
            }
          `}
        >
          <Box padding="0 15px">
            <Heading4
              align={ALIGN.CENTER}
              color={COLOR.BLACK}
              marginBottom={MARGIN.M40}
            >
              Frequently asked questions
            </Heading4>
            <AccordionSidebar data={faqs.garminPay} />
          </Box>
        </Box.Section>
        <ImportantInformation
          data={{
            content: {
              importantInformationSectionOne: [
                'Garmin, the Garmin logo, and the Garmin delta are trademarks of Garmin Ltd. or its subsidiaries and are registered in one or more countries, including the U.S.'
              ],
              importantInformationSectionTwo: [
                'Garmin Pay is a trademark of Garmin Ltd. or its subsidiaries.'
              ]
            }
          }}
          sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
          sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
        />
      </main>
    </Layout>
  );
}

export default GarminPay;
